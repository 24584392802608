import React from "react";
import ImageGallery from "react-image-gallery";

import style from "./Portfolio.module.css";

import project1img from "../../assets/img/project1.jpg";
import project2img from "../../assets/img/project2.jpg";
import project3img from "../../assets/img/project3.jpg";
import project4img from "../../assets/img/project4.jpg";

function Portfolio() {
  const images = [
    {
      original: project1img,
      thumbnail: project1img,
    },
    {
      original: project2img,
      thumbnail: project2img,
    },
    {
      original: project4img,
      thumbnail: project4img,
    },
    {
      original: project3img,
      thumbnail: project3img,
    },
  ];

  return (
    <section className={style.portfolio} id="portfolio_section">
      <div className={style.title}>
        <h1>PORTFOLIO</h1>
      </div>
      <div>
        <ImageGallery items={images} thumbnailPosition={"right"} showFullscreenButton={false} showPlayButton={false} autoPlay={true}/>
      </div>
    </section>
  );
}

export default Portfolio;
