import React from "react";
import Slide from "react-reveal/Slide";

import style from "./Services.module.css";

import webLogo from "../../assets/img/web-icon.png";
import wordpressLogo from "../../assets/img/wordpress-icon.png";
import androidLogo from "../../assets/img/android-icon.png";
import iosLogo from "../../assets/img/ios-icon.png";

function Services() {
  return (
    <section className={style.services} id="services_section">
      <div className={style.title}>
        <h1>SERVICES</h1>
      </div>
      <div className={style.row}>
        <div>
          <Slide left>
            <img src={webLogo} alt="Web Development" />
            <h3>
              WEB <br />
              DEVELOPMENT
            </h3>
          </Slide>
        </div>
        <div>
          <Slide left>
            <img src={androidLogo} alt="Android Development" />
            <h3>
              ANDROID <br />
              DEVELOPMENT
            </h3>
          </Slide>
        </div>
        <div>
          <Slide left>
            <img src={iosLogo} alt="IOS Development" />
            <h3>
              IOS <br />
              DEVELOPMENT
            </h3>
          </Slide>
        </div>
        <div>
          <Slide left>
            <img src={wordpressLogo} alt="Wordpress Development" />
            <h3>
              WORDPRESS <br />
              DEVELOPMENT
            </h3>
          </Slide>
        </div>
      </div>
      <Slide right>
        <a href="#header" className={style.myBtn}>
          ︽
        </a>
      </Slide>
    </section>
  );
}

export default Services;
