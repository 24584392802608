import Header from './components/Header/';
import Hero from './components/Hero/';
import Services from './components/Services/';
import Portfolio from './components/Portfolio/';
// import Team from './components/Team';
import Contact from './components/Contact';
import Footer from './components/Footer';

import './App.css';

function App() {
  return (
    <div className="App">
      <Header/>
      <Hero/>
      <Services/>
      <Portfolio/>
      {/* <Team/> */}
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
