import React from 'react'
import style from './Footer.module.css'

function Footer() {
    return (
        <footer className={style.footer}>
            <p>Copyright &copy; 2020-2021 by TEXVN X. All Rights Reserved.</p>
        </footer>
    )
}

export default Footer
