import React, { useState } from "react";
import Slide from "react-reveal/Slide";

import style from "./Contact.module.css";

import Loader from "../loader";

function Contact() {
  const [formData, setFormData] = useState({});
  const [formStatus, setformStatus] = useState("");
  const [loading, setLoading] = useState(false);

  let sendData = () => {
    setLoading(true)
    fetch("https://damp-castle-99446.herokuapp.com/contactSms", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        setformStatus("Message Sent, we will contact you soon !");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setformStatus("Some error occured !");
        setLoading(false);
      });
  };

  return (
    <section className={style.contact} id="contact_section">
      <div className={style.title}>
        <h1>CONTACT</h1>
      </div>
      <div className={style.row}>
        <Slide left>
          <div className={style.contact_form}>
            <h2>GET A QUOTE</h2>
            <form>
              <div>
                <label for="fname">Your Full Name</label>
                <input
                  type="text"
                  id="fname"
                  name="firstname"
                  placeholder="Your name.."
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  required
                />
              </div>
              <div>
                <label for="lname">Your Email Address</label>
                <input
                  type="email"
                  id="lname"
                  name="lastname"
                  placeholder="Your email address.."
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  required
                />
              </div>
              <div>
                <label for="phone">Your Phone No. (optional)</label>
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                  placeholder="Your phone no.."
                />
              </div>
              <div>
                <label for="company">Your Company Name (optional)</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  placeholder="Your company.."
                  onChange={(e) =>
                    setFormData({ ...formData, company: e.target.value })
                  }
                />
              </div>
              <div>
                <label for="subject">Message</label>
                <textarea
                  id="subject"
                  name="subject"
                  placeholder="Write something.."
                  style={{ height: "200px" }}
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                ></textarea>
              </div>
              <p style={{ color: "#e63946", padding: "5px 0px" }}>
                {formStatus}
              </p>
              {loading ? <Loader/>:<input type="button" value="SEND" onClick={() => sendData()} />}
            </form>
          </div>
        </Slide>
        <Slide right>
          <div className={style.contact_info}>
            <h2>CONNECT WITH US</h2>
            <ul>
              <li>
                <a target="blank" href="https://www.facebook.com/texvnx">
                  FACEBOOK
                </a>
              </li>
              <li>
                <a target="blank" href="https://twitter.com/m_maaz_azhar">
                  TWITTER
                </a>
              </li>
              <li>
                <a target="blank" href="https://github.com/m-maaz-azhar">
                  GITHUB
                </a>
              </li>
              <li>
                <a target="blank" href="mailto:texvnx@gmail.com">
                  E-MAIL
                </a>
              </li>
            </ul>
          </div>
        </Slide>
      </div>
    </section>
  );
}

export default Contact;
