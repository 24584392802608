import React from 'react'

import logo from '../../assets/img/logo.png'

import styles from './Header.module.css'

function Header() {
    return (
       <header className={styles.Header} id="header">
        <span>
            <img src={logo} alt="logo" width="180px"/>
        </span>
        <nav>
            <span>
                <a href="#services_section">SERVICES</a>
                <a href="#portfolio_section">PORTFOLIO</a>
                <a href="#team_section">TEAM</a>
                <a href="#contact_section">CONTACT</a>
            </span>
        </nav>
       </header>
    )
}

export default Header
