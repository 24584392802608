import React from "react";
import Typing from "react-typing-animation";

import style from "./Hero.module.css";

function Hero() {
  return (
    <div id={style.hero}>
      <div>
        <h1>WELCOME</h1>
        <Typing speed={60}>
          <span>We Build Things That Matters...</span>
        </Typing>
      </div>
    </div>
  );
}

export default Hero;
